/* eslint-disable no-else-return */
export const parseIterationDates = (start_day, end_day, itr_type) => {
  const start_date = new Date(start_day)
  const end_date = new Date(end_day)

  if (itr_type === 'Month') {
    const year = start_date.getUTCFullYear()
    const month = start_date.toLocaleString('default', { month: 'long', timeZone: 'GMT' })
    return `${month} ${year}`
  } else {
    const start_day = start_date.toLocaleString('default', { day: 'numeric', month: 'long', timeZone: 'GMT' })
    const adjustedEndDate = new Date(end_date)
    adjustedEndDate.setDate(adjustedEndDate.getDate() - 1)

    const end_day = adjustedEndDate.toLocaleString('default', {
      day: 'numeric', month: 'long', year: 'numeric', timeZone: 'GMT',
    })
    return `${start_day} - ${end_day}`
  }
}

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export function localeNormalizer(locale) {
  if (locale.includes('en')) return 'en'
  else if (locale.includes('es')) return 'es'
  else if (locale.includes('fr')) return 'fr'
  else if (locale.includes('pt')) return 'pt'
  else if (locale.includes('ca')) return 'ca'
  else return 'en'
}
